/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <div class="summary">
      <div class="description mb-32">
        <h1 class="h1 mb-32">
          {{ $t('confirmation.headline') }}
        </h1>
        <p class="pb-0">
          {{ $t('confirmation.defaultText') }}
        </p>
        <p class="text-center font-bold p-24 pb-0 text-24">
          {{ $route.params.confirmationNumber }}
        </p>
        <p class="text-center pb-24 text-10 text-gray">
          {{ $t('summary.confirmationCode') }}
        </p>
        <div v-if="hotel.details.onlineBookingSettings.websiteUrl && (hotel.details.onlineBookingSettings.websiteUrl[locale] || hotel.details.onlineBookingSettings.regionInformationUrl[locale])" class="actions">
          <div v-if="false" class="action">
            <users-icon size="40" />
            <span>{{ $t('confirmation.actions.preCheckIn') }}</span>
          </div>
          <div v-if="hotel.details.onlineBookingSettings.websiteUrl && hotel.details.onlineBookingSettings.websiteUrl[locale]" class="action" @click="openUrl(hotel.details.onlineBookingSettings.websiteUrl[locale])">
            <globe-icon size="40" />
            <span>{{ $t('confirmation.actions.hotelWebsite') }}</span>
          </div>
          <div v-if="hotel.details.onlineBookingSettings.regionInformationUrl && hotel.details.onlineBookingSettings.regionInformationUrl[locale]" class="action" @click="openUrl(hotel.details.onlineBookingSettings.regionInformationUrl[locale])">
            <info-icon size="40" />
            <span>{{ $t('confirmation.actions.regionalInformation') }}</span>
          </div>
        </div>
        <template v-if="hotel.details.onlineBookingSettings.reservationConfirmedPageContent && !isEmpty(hotel.details.onlineBookingSettings.reservationConfirmedPageContent[locale])">
          <!--          <p class="font-bold">-->
          <!--            {{ $t('confirmation.moreInformation') }}-->
          <!--          </p>-->
          <div v-html="dompurify(hotel.details.onlineBookingSettings.reservationConfirmedPageContent[locale])" />
        </template>
        <p>
          <span class="font-semibold">{{ hotel.hotel.hotelName }}</span><br />
          <span class="italic">{{ hotel.details.city }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoIcon, ChevronDownIcon, UsersIcon, GlobeIcon } from 'vue-feather-icons';
import Checkbox from '../InputElements/Checkbox/Checkbox';
import ButtonBig from '../ButtonBig/ButtonBig';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { FadeTransition } from 'vue2-transitions';
import { validationMixin } from 'vuelidate';
import TimerMixin from '@/mixins/TimerMixin';
import DOMPurify from 'dompurify';

export default {
  name: 'Confirmation',
  components: {
    FadeTransition,
    InfoIcon,
    ChevronDownIcon,
    checkbox: Checkbox,
    UsersIcon,
    GlobeIcon,
    'button-big': ButtonBig,
  },

  mixins: [DateRangeMixin, BookingEngineMixin, TimerMixin, validationMixin],

  props: {
  },

  data: function () {
    return {
    };
  },

  mounted() {
    // if (this.trackingEnabled) {
    //   this.$gtag.purchase({
    //     'transaction_id': this.$route.params.confirmationNumber,
    //     'affiliation': 'Online Booking',
    //     'value': 0.0,
    //   });
    // }
  },

  methods: {
    dompurify(s) {
      return DOMPurify.sanitize(s);
    },
    openUrl(url) {
      window.open(url, '_blank');
    },
  },

};
</script>

<style lang="scss">
  @import '~@/styles/import';

.summary {
  .description {
    p:not([class^="pb-"]):not([class*=" pb-"]) {
      @apply pb-16;
    }
  }

  .actions {
    @apply grid pt-16 pb-16 mb-24 border-t-1 border-b-1 border-gray;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    grid-row-gap: 3.6rem;

    @media (min-width: 1024px) {
      grid-auto-flow: column;
    }
    .action {
      @apply block text-center font-semibold grid text-gray cursor-pointer justify-items-center;
      grid-template-columns: 1fr;
      grid-auto-rows: 4rem 1fr;
      grid-row-gap: 1rem;
      &:hover {
        @apply text-dark;
      }
    }
  }
}
</style>
