<template>
  <button class="button-big" :disabled="disabled" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>

export default {
  name: 'ButtonFloating',
  components: { },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {

    };
  },

  computed: {

  },

  watch: {

  },

  created: function () {

  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {

  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';
  .button-big {
    @apply h-64 w-full flex items-center justify-center px-30 py-22 bg-primary z-50 text-white rounded-32 font-semibold;
    &:disabled {
      @apply bg-grayBlue cursor-not-allowed;
    }
  }
</style>
